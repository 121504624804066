<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-08 10:08:29
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-09 19:42:09
 * @FilePath: \official_website\src\mobile\components\Footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="footer">
    <ul class="menu-box">
      <li v-for="(item, index) in menu" :key="index">
        <div class="son-title" @click="toggleSubmenu(index)">
          <span class="title">
            {{ item.title }}
          </span>
          <i
            class="iconfont icon-jiahao"
            v-show="!item.isActive"
            v-if="item.children"
          ></i>
          <i
            class="iconfont icon-jianhao"
            v-show="item.isActive"
            v-if="item.children"
          ></i>
        </div>
        <ul v-show="item.isActive" v-if="item.children">
          <li
            v-for="(subItem, subIndex) in item.children"
            :key="subIndex"
            @click="tapPage(subItem)"
          >
            <div class="son-title" style="padding-left: 30px">
              <span
                class="title"
                :class="subItem.path == currentPath && currentPath != '/product' ? 'hight-active' : ''"
              >
                {{ subItem.title }}
              </span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <div class="contact">
      <div class="logo-box" @click="tapLogo">
        <img src="@/assets/image/home/logo.png" alt="" />
      </div>
      <div class="adress-box">
        <div class="adress">
          <i class="el-icon-location"></i>
          <span>地址：长沙高新开发区麓天路28号金瑞麓谷科技园D1栋5~6楼</span>
        </div>
        <div class="telphone">
          <i class="el-icon-phone"></i>
          <div class="tel-box">
            <div class="left">电话：</div>
            <div class="right">
              <p>0731-88737348（总机）</p>
              <p>18873193239（商务咨询）</p>
            </div>
          </div>
        </div>
        <div class="email"></div>
      </div>
    </div>
    <div class="links">
      <div class="title">联系我们</div>

      <div class="code-box">
        <div class="public">
          <img src="../../assets/image/home/barcode.jpg" alt="" />
          <div class="text">微信公众号</div>
        </div>
        <div class="business">
          <img src="../../assets/image/home/business.jpg" alt="" />
          <div class="text">商务号</div>
        </div>
      </div>
    </div>
    <div class="footer-bottom reveal-bottom">
      <span>
        版权所有品信生物工程 © 2015-2022. 备案号:
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >湘ICP备15003521号</a
        >
        <a href="//tongji.baidu.com/web/26696728/overview/index" target="_blank"
          >百度统计</a
        >
      </span>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      currentPath: "",
      menu: [
        {
          title: "解决方案",
          isActive: false,
          children: [
            {
              title: "宫颈筛查解决方案",
              isActive: false,
              path: "/product/tbs",
            },
            {
              title: "DNA倍体癌症早筛解决方案",
              isActive: false,
              path: "/product/dna",
            },
            {
              title: "远程病理诊断解决方案",
              isActive: false,
              path: "/product/telepathology",
            },
            {
              title: "血液细胞形态解决方案",
              isActive: false,
              path: "/product/blood",
            },
          ],
        },
        {
          title: "产品",
          isActive: false,
          children: [
            { title: "试剂", isActive: false, path: "/product" },
            { title: "数字切片扫描仪", isActive: false, path: "/product" },
            { title: "液基细胞学前处理", isActive: false, path: "/product" },
            { title: "人工智能辅助诊断", isActive: false, path: "/product" },
          ],
        },
        {
          title: "关于品信",
          isActive: false,
          children: [
            { title: "公司介绍", isActive: false, path: "/about" },
            { title: "人才招募", isActive: false, path: "/contact" },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {},
  watch: {
    "$route.path": {
      handler(to, form) {
        this.currentPath = to;
      },
      // 深度观察监听
      deep: true,
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    toggleSubmenu(index) {
      const item = this.menu[index];
      item.isActive = !item.isActive;
      // 可以添加逻辑处理点击子菜单项时关闭其他菜单
      this.menu.forEach((item, indexs) => {
        if (indexs != index) {
          item.isActive = false;
        }
      });
    },
    tapPage(value) {
      if (value.path) {
        this.$router.push({ path: value.path });
      }
    },
    tapLogo() {
      this.$nextTick(() => {
        document.getElementById("app").scrollTop = 0;
      });
    },
  },
};
</script>
    
<style lang="scss">
#footer {
  .menu-box {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dcdddd;
    li {
      cursor: pointer;
      border-top: 1px solid #dcdddd;
      font-size: 1rem;
    }
    .son-title {
      padding: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
      }
      .iconfont {
        font-size: 1.375rem;
        font-weight: 700;
        color: #666;
      }
    }
  }
  .contact {
    padding: 1rem;
    .logo-box {
      width: 50%;
      margin-bottom: 1rem;
      img {
        width: 100%;
      }
    }
    .adress-box {
      font-size: 0.8rem;
      font-family: "OPlusSans3-Regular";
      color: #666666;
      .adress {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        i {
          display: block;
          padding-top: 0.25rem;
          margin-right: 0.5rem;
        }
      }
      .telphone {
        display: flex;
        i {
          display: block;
          padding-top: 0.25rem;
          margin-right: 0.5rem;
        }
        .tel-box {
          display: flex;
          .left {
          }
          .right {
            p {
              margin-bottom: 0.3rem;
            }
          }
        }
      }
    }
  }
  .links {
    width: 100%;
    padding: 1rem;
    .title {
      font-size: 1rem;
      margin-bottom: 0.8rem;
      font-family: "SourceHanSansSC-Medium";
    }
    .code-box {
      display: flex;
      justify-content: center;
      width: 100%;
      .public {
        width: 30%;
        margin-right: 10%;
        img {
          width: 100%;
          border: 1px solid #0b847b;
        }
        .text {
          text-align: center;
          font-size: 0.8rem;
          font-family: "OPlusSans3-Regular";
          color: #666666;
        }
      }
      .business {
        width: 30%;
        img {
          width: 100%;
          border: 1px solid #0b847b;
        }
        .text {
          text-align: center;
          font-size: 0.8rem;
          font-family: "OPlusSans3-Regular";
          color: #666666;
        }
      }
    }
  }
  .footer-bottom {
    padding: 0 3rem;
    opacity: 0.7;
    line-height: 2;
    text-align: center;
    font-size: 0.75rem;
    color: #4d4d4d;
    a {
      text-decoration: underline;
      color: #4d4d4d;
      transition: all 0.5s;
      margin-right: 0.3125rem;
      &:hover {
        color: #0b847b;
        transition: all 0.5s;
      }
    }
  }
  .hight-active {
    color: #0b847b !important;
  }
}
</style>