<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-16 16:29:54
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-09 19:21:53
 * @FilePath: \official_website\src\mobile\components\Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="header">
    <div class="header-box">
      <div class="left" @click="tapLog('noNeed')">
        <img src="@/assets/image/home/logo.png" alt="" />
      </div>
      <div class="right">
        <i class="iconfont icon-jiugongge" @click="showMenu"></i>
      </div>
    </div>

    <van-popup
      v-model="menuFlag"
      class="menu-pop"
      position="top"
      closeable
      :style="{ height: '100%' }"
    >
      <div class="logo-box" @click="tapLog('need')">
        <img src="@/assets/image/home/logo.png" alt="" />
      </div>

      <ul class="menu-box">
        <li v-for="(item, index) in menu" :key="index">
          <div class="son-title" @click="toggleSubmenu(index, item)">
            <span class="title" :class="item.path == currentPath ? 'hight-active' : ''">
              {{ item.title }}
            </span>
            <i
              class="iconfont icon-jiahao"
              v-show="!item.isActive"
              v-if="item.children"
            ></i>
            <i
              class="iconfont icon-jianhao"
              v-show="item.isActive"
              v-if="item.children"
            ></i>
          </div>
          <ul v-show="item.isActive" v-if="item.children">
            <li v-for="(subItem, subIndex) in item.children" :key="subIndex">
              <div
                class="son-title"
                style="padding-left: 30px"
                :class="subItem.isActive ? 'hight-active' : ''"
                @click="togglenNextSubmenu(subIndex, item.children)"
              >
                <span class="title">
                  {{ subItem.title }}
                </span>
                <i
                  class="iconfont icon-jiahao"
                  v-show="!subItem.isActive"
                  v-if="subItem.children"
                ></i>
                <i
                  class="iconfont icon-jianhao"
                  v-show="subItem.isActive"
                  v-if="subItem.children"
                ></i>
              </div>
              <ul v-show="subItem.isActive" v-if="subItem.children">
                <li
                  v-for="(grandchild, grandIndex) in subItem.children"
                  :key="grandIndex"
                >
                  <div
                    class="son-title"
                    style="padding-left: 50px"
                    :class="
                      grandchild.isActive && grandchild.children
                        ? 'hight-active'
                        : ''
                    "
                    @click="
                      togglenFinalSubmenu(
                        grandIndex,
                        subItem.children,
                        grandchild
                      )
                    "
                  >
                    <span class="title" :class="grandchild.path == currentPath ? 'hight-active' : ''">
                      {{ grandchild.title }}
                    </span>
                    <i
                      class="iconfont icon-jiahao"
                      v-show="!grandchild.isActive"
                      v-if="grandchild.children"
                    ></i>
                    <i
                      class="iconfont icon-jianhao"
                      v-show="grandchild.isActive"
                      v-if="grandchild.children"
                    ></i>
                  </div>
                  <ul v-show="grandchild.isActive" v-if="grandchild.children">
                    <li
                      v-for="(lastchild, lastIndex) in grandchild.children"
                      :key="lastIndex"
                    >
                      <div
                        class="son-title"
                        style="padding-left: 70px"
                        @click="tapPage(lastchild)"
                        :class="lastchild.path == currentPath ? 'hight-active' : ''"
                      >
                        {{ lastchild.title }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </van-popup>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      menuFlag: false,
      menu: [
        {
          title: "关于品信",
          isActive: false,
          path: "/about",
        },
        {
          title: "产品及解决方案",
          isActive: false,
          children: [
            {
              title: "解决方案",
              isActive: false,
              children: [
                {
                  title: "宫颈筛查解决方案",
                  isActive: false,
                  path: "/product/tbs",
                },
                {
                  title: "DNA倍体癌症早筛解决方案",
                  isActive: false,
                  path: "/product/dna",
                },
                {
                  title: "远程病理诊断解决方案",
                  isActive: false,
                  path: "/product/telepathology",
                },
                {
                  title: "血液细胞形态解决方案",
                  isActive: false,
                  path: "/product/blood",
                },
              ],
            },
            {
              title: "产品",
              isActive: false,
              children: [
                {
                  title: "诊断试剂包",
                  isActive: false,
                  children: [
                    {
                      title: "液基细胞学TCT诊断试剂",
                      isActive: false,
                      path: "/product/tctReagent",
                    },
                    {
                      title: "DNA定量分析诊断试剂",
                      isActive: false,
                      path: "/product/dnaReagent",
                    },
                  ],
                },
                {
                  title: "数字切片扫描仪",
                  isActive: false,
                  children: [
                    {
                      title: "吉米娜系列",
                      isActive: false,
                      path: "/product/gemini",
                    },
                  ],
                },
                {
                  title: "液基细胞学前处理",
                  isActive: false,
                  children: [
                    {
                      title: "液基制片染色一体机",
                      isActive: false,
                      path: "/product/pelleter",
                    },
                    {
                      title: "液基膜式制片机",
                      isActive: false,
                      path: "/product/film",
                    },
                    {
                      title: "全自动特殊染色机",
                      isActive: false,
                      path: "/product/special",
                    },
                  ],
                },
                {
                  title: "血液细胞形态",
                  isActive: false,
                  children: [
                    {
                      title: "骨髓/血液细胞形态学AI扫描分析系统",
                      isActive: false,
                      path: "/product/marrow",
                    },
                    {
                      title: "全自动推片染色机",
                      isActive: false,
                      path: "/product/bloodPushing",
                    },
                  ],
                },
                {
                  title: "人工智能辅助诊断",
                  isActive: false,
                  children: [
                    {
                      title: "DNA倍体定量分析系统",
                      isActive: false,
                      path: "/product/dnaProd",
                    },
                    {
                      title: "细胞学TCT人工智能辅助诊断",
                      isActive: false,
                      path: "/product/tctProd",
                    },
                    {
                      title: "远程病理会诊系统",
                      isActive: false,
                      path: "/product/patholog",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "媒体中心",
          isActive: false,
          path: "/medium",
        },
        {
          title: "学术园地",
          isActive: false,
          path: "/science",
        },
        {
          title: "人才招募",
          isActive: false,
          path: "/contact",
        },
        {
          title: "品信十周年",
          isActive: false,
          path: "/decennial",
        },
      ],
      currentPath: "",
    };
  },
  created() {},
  mounted() {},
  watch: {
    '$route.path': {
      handler(to, form) {
        this.currentPath = to
      },
      // 深度观察监听
      deep: true,
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    tapLog(type) {
      this.$router.push({ path: "/" });
      // if(type == 'need') {
      //   this.menuFlag = false;
      // }
    },
    showMenu() {
      this.menuFlag = true;
    },
    toggleSubmenu(index, value) {
      const item = this.menu[index];
      item.isActive = !item.isActive;
      // 可以添加逻辑处理点击子菜单项时关闭其他菜单

      if (value.path) {
        // if(value.path == this.$route.path) {
        //   this.menuFlag = false;
        // } else {
        this.$router.push({ path: value.path });
        // }
      }
    },
    togglenNextSubmenu(subIndex, subMenu) {
      const subItem = subMenu[subIndex];
      subItem.isActive = !subItem.isActive;

      subMenu.forEach((item, index) => {
        if (subIndex != index) {
          item.isActive = false;
        }
      });
    },
    togglenFinalSubmenu(grandIndex, grandMenu, grandchild) {
      const grandItem = grandMenu[grandIndex];
      grandItem.isActive = !grandItem.isActive;

      grandMenu.forEach((item, index) => {
        if (grandIndex != index) {
          item.isActive = false;
        }
      });

      if (grandchild.path) {
        this.$router.push({ path: grandchild.path });
      }
    },
    tapPage(value) {
      if (value.path) {
        this.$router.push({ path: value.path });
      }
    },
  },
};
</script>
    
<style lang="scss">
#header {
  .header-box {
    width: 100%;
    height: 3.75rem;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    .left {
      img {
        height: 2.4rem;
      }
    }
    .right {
      i {
        font-size: 1.75rem;
        font-weight: 700;
      }
    }
  }
  .menu-pop {
    .van-popup__close-icon {
      color: #333;
      top: 1rem !important;
    }
    .logo-box {
      padding: 0.5rem 1rem;
      // border-bottom: 1px solid #ccc;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      img {
        height: 2.4rem;
      }
    }
    .menu-box {
      list-style-type: none;
      padding: 0;
      border-bottom: 1px solid #dcdddd;
      li {
        cursor: pointer;
        border-top: 1px solid #dcdddd;
        font-size: 1rem;
      }
      .son-title {
        padding: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
        }
        .iconfont {
          font-size: 1.375rem;
          font-weight: 700;
          color: #666;
        }
      }
    }
  }
  .hight-active {
    color: #0b847b !important;
  }
}
</style>